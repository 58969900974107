
        import * as module0 from './blog_post_anchor_controller.js';import * as module1 from './discord_controller.js';import * as module2 from './image_controller.js';import * as module3 from './navbar_controller.js';import * as module4 from './photoswipe_controller.js';import * as module5 from './tooltip_controller.js'
        const modules = {
            "./blog_post_anchor_controller.js": module0,
            "./discord_controller.js": module1,
            "./image_controller.js": module2,
            "./navbar_controller.js": module3,
            "./photoswipe_controller.js": module4,
            "./tooltip_controller.js": module5,
        };
        export default modules;
      