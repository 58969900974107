import { Controller } from "@hotwired/stimulus"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import PhotoSwipe from 'photoswipe'

export default class extends Controller {
  connect() {
    this.lightbox = new PhotoSwipeLightbox({
      gallery: `#${this.element.id}`,
      children: 'img[data-pswp-src]',
      thumbSelector: 'img',
      showHideAnimationType: 'fade',
      pswpModule: PhotoSwipe
    })

    this.lightbox.addFilter('itemData', (itemData, index) => {
      const { pswpSrc, pswpWidth, pswpHeight } = itemData.element.dataset

      itemData.src = pswpSrc;
      itemData.width = pswpWidth
      itemData.height = pswpHeight

      return itemData;
    });

    this.lightbox.on('uiRegister', () => {
      this.lightbox.pswp.ui.registerElement({
        name: 'custom-caption',
        order: 9,
        isButton: false,
        appendTo: 'root',
        html: 'Caption text',
        onInit: (element, pswp) => {
          this.lightbox.pswp.on('change', () => {
            const currentSlideElement = this.lightbox.pswp.currSlide.data.element;

            let captionHTML = '';

            if (currentSlideElement) {
              const hiddenCaption = currentSlideElement.querySelector('.hidden-caption-content');

              if (hiddenCaption) {
                // get caption from element with class hidden-caption-content
                captionHTML = hiddenCaption.innerHTML;
              } else {
                // get caption from alt attribute
                captionHTML = currentSlideElement.getAttribute('alt');

                if (captionHTML === null || captionHTML === "") {
                  captionHTML = currentSlideElement.dataset.credit
                }
              }
            }
            element.innerHTML = captionHTML || '';
          });
        }
      });
    });

    this.lightbox.init()
  }

  open(event) {
    const photos = Array.from(this.element.querySelectorAll(".talk-photo"))
    const index = photos.indexOf(event.target.closest(".talk-photo"))

    this.lightbox.loadAndOpen(index)
  }

  disconnect() {
    if (this.element.lightbox) {
      this.element.lightbox.destroy()
    }
  }
}
