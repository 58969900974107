import { Controller } from "@hotwired/stimulus"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import PhotoSwipe from 'photoswipe'

export default class extends Controller {
  connect() {

    if (!this.lightbox) {
      document.querySelectorAll('#content img[src]:not([nocaption=true])').forEach(element => {
        const figcaption = document.createElement("figcaption")
        figcaption.classList.add("text-center", "text-sm", "-mt-6")

        let parent = element.closest(".no-captions-on-mobile")

        if (parent) {
          figcaption.classList.add("hidden", "md:block")
        }

        figcaption.innerText = element.alt

        element.parentNode.appendChild(figcaption, element.nextSibling)
        element.classList.add("cursor-zoom-in")

        if (parent) {
          element.classList.add("m-0", "md:my-8")
        }
      })
    }

    this.lightbox = new PhotoSwipeLightbox({
      gallery: `#${this.element.id}`,
      children: '#content img[src]:not([nocaption=true])',
      thumbSelector: '#content img[src]:not([nocaption=true])',
      showHideAnimationType: 'fade',
      pswpModule: PhotoSwipe
    })

    this.lightbox.addFilter('itemData', (itemData, index) => {
      const { pswpSrc, pswpWidth, pswpHeight } = itemData.element.dataset

      itemData.src = pswpSrc || itemData.element.src;
      itemData.width = pswpWidth || itemData.element.naturalWidth;
      itemData.height = pswpHeight || itemData.element.naturalHeight;

      return itemData;
    });

    this.lightbox.on('uiRegister', () => {
      this.lightbox.pswp.ui.registerElement({
        name: 'custom-caption',
        order: 9,
        isButton: false,
        appendTo: 'root',
        html: 'Caption text',
        onInit: (element, pswp) => {
          this.lightbox.pswp.on('change', () => {
            const currentSlideElement = this.lightbox.pswp.currSlide.data.element;

            let captionHTML = '';

            if (currentSlideElement) {
              const hiddenCaption = currentSlideElement.querySelector('.hidden-caption-content');

              if (hiddenCaption) {
                // get caption from element with class hidden-caption-content
                captionHTML = hiddenCaption.innerHTML;
              } else {
                // get caption from alt attribute
                captionHTML = currentSlideElement.getAttribute('alt');

                if (captionHTML === null || captionHTML === "") {
                  captionHTML = currentSlideElement.dataset.credit
                }
              }
            }
            element.innerHTML = captionHTML || '';
          });
        }
      });
    });

    this.lightbox.init()
  }

  open(event) {
    const photos = Array.from(this.element.querySelectorAll(".talk-photo"))
    const index = photos.indexOf(event.target.closest(".talk-photo"))

    this.lightbox.loadAndOpen(index)
  }

  disconnect() {
    if (this.element.lightbox) {
      this.element.lightbox.destroy()
    }
  }
}
